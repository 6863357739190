var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-three-fifths"},[_c('DefaultForm',{attrs:{"is-saving":_vm.isSaving,"mode":_vm.mode},on:{"submit":_vm.onSubmit,"abort":_vm.onAbort},scopedSlots:_vm._u([{key:"default",fn:function(formSettings){return [_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('general.name')),"type":_vm.getValidationErrors && _vm.getValidationErrors.name
                  ? 'is-danger'
                  : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.name
                  ? _vm.getValidationErrors.name
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('placeholder.name')),"required":""},model:{value:(_vm.uName),callback:function ($$v) {_vm.uName=$$v},expression:"uName"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('Email')),"type":_vm.getValidationErrors && _vm.getValidationErrors.email
                  ? 'is-danger'
                  : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.email
                  ? _vm.getValidationErrors.email
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('placeholder.email')),"required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('general.password')),"type":_vm.getValidationErrors && _vm.getValidationErrors.password
                  ? 'is-danger'
                  : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.password
                  ? _vm.getValidationErrors.password
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('placeholder.password')),"required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1)]}}])},[_c('SaveButton',{on:{"click":_vm.doSomething}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }